import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import ShowAllMoviesView from "../views/ShowAllMoviesView.vue";

import Login from "../views/auth/AuthLogin.vue";
import Register from "../views/auth/AuthRegister.vue";

import store from "@/store";
import { MovieAddRoutes } from "./MovieAddRoutes";
import { GroupRoutes } from "./GroupRoutes";
import { MediaMatchRoutes } from "./MediaMatchRoutes";

Vue.use(VueRouter);

Vue.config.ignoredElements = [/^ion-/];

const routes = [
  {
    path: "/",
    name: "Base",
    redirect: { name: "Main" },
  },
  {
    path: "/movies",
    name: "Main",
    component: Main,
  },
  {
    path: "/movies/all",
    name: "AllMovies",
    component: ShowAllMoviesView,
  },
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
  },
  GroupRoutes,
  MovieAddRoutes,
  MediaMatchRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!to.path.includes("/auth/")) {
    try {
      await store.dispatch("init");
    } catch (error) {
      next({ name: "Login" });
    }
  }

  next();
});

export default router;
