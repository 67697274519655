<template>
    <div v-if="pick">
        <div class="flex justify-center place-items-center overflow-hidden flex-grow flex-shrink min-h-0">
            <img class="h-full bg-secondary-dark text-transparent rounded-2xl" style="aspect-ratio: 60/89;" :src="getResourceUrl(pick.poster, 'w342')" :alt="pick.title">
        </div>
        <h1 class="text-white text-3xl my-6" >Is now ranked <br> <b> {{ rank }}</b> </h1>
        <base-icon-button
            class="place-self-end"
            value="continue"
            icon="chevron-forward-outline"
            @click="$emit('movie-finalize')"
        ></base-icon-button>
    </div>
</template>

<script>
import BaseIconButton from '@/components/base/BaseIconButton'
import { getResourceUrl } from "@/providers/UrlProvider";

export default {
    props: ['pick', 'rank'],
    components: {
        BaseIconButton
    },
    methods: {
        getResourceUrl
    }
}
</script>

<style scoped>

    h1 {
        margin-top: 0;
        font-weight: normal;
        text-align: center;
    }

    b {
        text-transform: capitalize;
    }


</style>