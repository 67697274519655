import axios from "@/providers/HttpProvider";
import Vue from "vue";
import Vuex from "vuex";

import { getSubscription, subscribe } from "../providers/PushProvider";

import groupState from "./groups";
import watchlistState from "./watchlist";
import matchState from "./matches";
import notificationState from "./notifications";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    me: undefined,
  },
  mutations: {
    me: (state, me) => {
      state.me = me;
    },
    sub: (state, hasSubscription) => {
      Vue.set(state.me, "hasSubscription", hasSubscription);
    },
    subReject: (state) => {
      const time = Date.now();

      state.me.rejectNotifications = time;

      localStorage.setItem("notifications.rejected_at", time);
    },
  },
  getters: {
    shouldPromptNotifications: (state) => () => {
      if (localStorage.getItem("notifications.rejected_at")) return false;
      if (!"serviceWorker" in navigator) return false;

      return !state.me.hasSubscription;
    },
  },
  actions: {
    me: async ({ state, commit }) => {
      if (state.me) return;

      try {
        let { data: me } = await axios.get("/auth/me");

        commit("me", me);

        const subscription = await getSubscription();

        if (subscription)
          await axios.post("/auth/pushsub", subscription.toJSON());

        commit("sub", Boolean(subscription));
      } catch (error) {
        throw error;
      }
    },
    init: async ({ state, dispatch }) => {
      if (state.me) return;

      await dispatch("me");
      await dispatch(
        "groupState/setActiveGroup",
        localStorage.getItem("groups.active")
      );
    },
    subscribe: async ({ commit }) => {
      try {
        const subscription = await subscribe();

        if (subscription)
          await axios.post("/auth/pushsub", subscription.toJSON());

        commit("sub", Boolean(subscription));
      } catch (error) {
        throw error;
      }
    },
  },
  modules: {
    groupState,
    matchState,
    watchlistState,
    notificationState,
  },
});

export default store;
