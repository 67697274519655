<template>
      <div class="flex flex-col justify-center w-full h-full relative">
        <div class="flex justify-end">
          <router-link :to="{name:'Main'}">
              <ion-icon class="text-white text-2xl" name="chevron-forward"></ion-icon>
          </router-link>
        </div>
        <div class="flex-shrink min-h-0 overflow-y-auto rounded-xl mt-2 mb-auto">
            <movie-table-component
                class="flex-shrink min-h-0"
                :spotlight="spotlight"
                :search="search"
                @select="focus_media = $event"
            ></movie-table-component>
        </div>
        <group-select-component
           class="mt-4"
            @change="spotlight = $event"
        ></group-select-component>
        <label class="input mt-4">
            <input type="text" placeholder="Search" v-model="search">
            <span class="p-3 text-base leading-4 text-black hover:text-gray-500 cursor-pointer">
                <ion-icon v-if="!search.length" name="search"></ion-icon>
                <ion-icon v-else name="close" @click="search = ''"></ion-icon>
            </span>
        </label>
        <transition name="slide-bottom">
          <watched-detail-component
            v-if="focus_media"
            class="z-10"
            :media="focus_media"
            @close="focus_media = undefined"
          > </watched-detail-component>
        </transition>
      </div>
</template>

<script>
import MovieTableComponent from "@/components/MovieTableComponent";
import GroupSelectComponent from '../components/GroupSelectComponent.vue';
import WatchedDetailComponent from '../components/WatchedDetailComponent.vue';

export default {
  name: 'Main',
  data(){
    return {
      spotlight: undefined,
      focus_media: undefined,
      search: ''
    }
  },
  components: {
    MovieTableComponent,
    GroupSelectComponent,
    WatchedDetailComponent
  },
}
</script>