<template>
  <component
    :is="tag"
    :class="color_class"
    class="inline-flex scale-100 transform cursor-pointer select-none place-items-center rounded-full p-2 font-semibold shadow-xl active:scale-90"
    v-on="$listeners"
  >
    <span v-if="value && !icon_left" class="mx-2 uppercase">{{
      this.value
    }}</span>

    <ion-icon
      v-if="icon && !loading"
      class="visible p-1 text-lg"
      :class="icon_margin"
      :name="this.icon"
    ></ion-icon>
    <ion-icon
      v-else
      class="visible text-lg"
      :class="icon_margin"
      src="/assets/spinner.svg"
    ></ion-icon>

    <span v-if="value && icon_left" class="mx-2 uppercase">{{
      this.value
    }}</span>
  </component>
</template>

<script>
export default {
  name: "BaseIconButton",
  props: {
    value: String,
    icon: String,
    icon_left: Boolean,
    tag: {
      default: "a",
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    primary: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    icon_margin() {
      if (this.value) return this.icon_left ? "mr-auto" : "ml-auto";

      return "";
    },
    color_class() {
      return {
        "bg-glass-800": !this.primary,
        "text-secondary": !this.primary,
        "hover:bg-glass-900": !this.primary,
        "bg-secondary": this.primary,
        "hover:bg-secondary-light": this.primary,
        "text-white": this.primary,
      };
    },
  },
};
</script>

<style scoped>
a {
  transition: transform 0.2s cubic-bezier(0.68, -0.6, 0.32, 2);
}
</style>
