import axios from 'axios';
import { auth } from './AuthProvider';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
})

instance.interceptors.request.use(config => {

    try {        
        const token = auth.token();
        config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
        throw error;
    }

    return config;
})

export default instance;