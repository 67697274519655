<template>
  <div class="pointer-events-none absolute inset-0 flex h-full w-full flex-col">
    <transition name="expand">
      <div
        v-show="show_results"
        class="bg-glass-800 results pointer-events-auto absolute bottom-0 left-0 right-0 flex flex-col overflow-hidden rounded-3xl"
      >
        <ul class="list overflow-x-hidden" ref="result">
          <li
            class="listitem h-20"
            v-for="media of results"
            :key="media.id"
            @click="
              $router.push({
                name: 'MovieAdd',
                params: { media_type: media.media, media_id: media.id },
              })
            "
          >
            <div
              v-if="media.poster == ''"
              class="bg-primary-light h-20 rounded-lg opacity-50"
              style="aspect-ratio: 60/89"
            ></div>
            <img
              v-else
              class="bg-primary-light h-20 rounded-lg"
              style="aspect-ratio: 60/89"
              :src="getResourceUrl(media.poster, 'w92')"
              :alt="media.title"
            />
            <div
              class="m-0 ml-2 flex max-h-full min-w-0 flex-shrink flex-col place-self-start"
            >
              <p class="line-clamp-2 text-xs font-semibold sm:text-sm">
                {{ media.title }}
              </p>
              <small
                class="flex-shring line-clamp-2 min-h-0 text-xs opacity-70 sm:text-sm"
                >{{ media.desc }}</small
              >
            </div>
            <ion-icon
              class="text-lg sm:text-2xl"
              name="add-circle-outline"
            ></ion-icon>
          </li>
        </ul>
      </div>
    </transition>
    <label
      class="focus-within:ring-secondary-light pointer-events-auto z-10 mt-auto flex w-full rounded-full bg-white focus-within:ring-2"
    >
      <input
        class="w-full border-0 bg-transparent focus:ring-0"
        type="text"
        v-model="q"
        placeholder="Search..."
        @keyup.enter="search"
        @focusin="focused = true"
        @focusout="focused = false"
      />

      <span
        class="cursor-pointer p-3 text-base leading-4 text-black hover:text-gray-500"
      >
        <ion-icon v-if="loading" src="/assets/spinner.svg"></ion-icon>
        <ion-icon v-else @click="clear" :name="search_icon"></ion-icon>
      </span>
    </label>
  </div>
</template>

<script>
import axios from "@/providers/HttpProvider";
import { getResourceUrl } from "@/providers/UrlProvider";

export default {
  name: "MovieSearchComponent",
  data() {
    return {
      q: "",
      focused: false,
      loading: false,
      results: false,
    };
  },
  methods: {
    getResourceUrl,
    async search() {
      try {
        this.loading = true;
        let q = encodeURIComponent(this.q);
        let res = await axios.get(`media/search/${q}`);
        let data = res.data;

        data.reverse();
        this.results = res.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.q = "";
    },
  },
  computed: {
    show_results() {
      return this.results && this.focused && this.q;
    },
    search_icon() {
      return this.q == "" ? "search" : "close";
    },
  },
  watch: {
    results() {
      this.$nextTick(() => {
        this.$refs.result.scroll(0, this.$refs.result.scrollHeight);
      });
    },
  },
};
</script>

<style scoped>
.results {
  max-height: calc(100% - 1rem);
  backdrop-filter: blur(8px);
  transform: scaleY(1);
  opacity: 1;
}

.list {
  list-style: none;
  height: calc(100% - 3rem);
  padding: 0 1.5rem;
  margin: 1.5rem 0 3rem 0;
  overflow-y: auto;
}

.listitem {
  margin-bottom: 0.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: hsl(259, 80%, 28%);
}

.listitem:hover {
  background: hsla(324, 78%, 36%, 0.2);
  color: hsl(324, 78%, 36%);
}

.listitem img {
  margin: 0;
  padding: 0;
  box-shadow: var(--shadow);
}

.listitem:hover ion-icon {
  animation: pulsate-fwd 0.25s ease-in-out forwards;
}

.listitem ion-icon {
  padding: 1rem;
  flex-shrink: 0;
  margin-left: auto;
}

.search-container {
  position: relative;
  margin-top: auto;
}

.expand-enter-active,
.expand-leave-active {
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  transform-origin: bottom;
}

.expand-leave-active {
  transition-delay: 250ms;
}

.expand-enter,
.expand-leave-to {
  transform: scaleY(0);
  opacity: 0;
}
</style>
