<template>
  <div v-if="pick" class="flex h-full w-full flex-col">
    <h1 class="text-center text-3xl font-black text-white">{{ pick.title }}</h1>
    <router-view
      class="flex min-h-0 flex-shrink flex-grow flex-col"
      :pick="pick"
      :rank="post_data.rank"
      :watchlist="watchlist"
      @movie-rate="rank($event)"
      @movie-init="init($event)"
      @movie-finalize="store()"
    ></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/providers/HttpProvider";

export default {
  props: {
    media_type: String,
    media_id: [String, Number],
  },
  data() {
    return {
      pick: undefined,
      post_data: {
        comment: "",
        rank: 0,
      },
      watchlist: [],
      watchcount: 0,
    };
  },
  methods: {
    ...mapActions("notificationState", ["notify"]),
    rank({ rating }) {
      const R = this.watchlist.length - 1;
      const M = Math.floor(R / 2.0);

      if (
        this.watchlist.length == 1 ||
        (this.watchlist.length == 2 && rating)
      ) {
        const delta = !rating ? 1 : 0;

        this.post_data.rank = this.watchlist[0].rank + delta;
        this.$router.replace({ name: "MovieAddFinalize" });
        return;
      }

      if (rating) {
        this.watchlist = this.watchlist.slice(0, M);
      } else {
        this.watchlist = this.watchlist.slice(M + 1, R + 1);
      }
    },
    init({ comment }) {
      this.post_data.comment = comment;

      if (this.watchlist.length != 0) {
        this.$router.replace({ name: "MovieAddRate" });
      } else {
        this.post_data.rank = 1;
        this.$router.replace({ name: "MovieAddFinalize" });
      }
    },
    async store() {
      try {
        await axios.post(
          `/media/store/${this.media_type}/${this.pick.ext_id}`,
          this.post_data
        );
      } catch (error) {
        this.notify({
          title: "Error",
          text: "Something went wrong.",
        });
      } finally {
        this.$router.replace({ name: "Main" });
      }
    },
  },
  async created() {
    try {
      let list = await axios.get("/media/list");
      let pick = await axios.get(
        `/media/get/${this.media_type}/${this.media_id}`
      );

      this.watchlist = list.data;
      this.watchcount = this.watchlist.length;
      this.pick = pick.data;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
