import MediaMatchView from "@/views/matches/MediaMatchView";
import store from "../store";

const MediaMatchRoutes = {
  path: "/matches",
  name: "Matches",
  component: MediaMatchView,
  async beforeEnter(to, from, next) {
    try {
      await store.dispatch("matchState/getSuggestions");
      next();
    } catch (error) {
      next(from);
    }
  },
  children: [
    //   {
    //     path: ":group_id/invite",
    //     name: "GroupInvite",
    //     component: GroupInviteView,
    //     props: true,
    //   },
    //   {
    //     path: "add",
    //     name: "GroupCreate",
    //     component: GroupCreateView,
    //   },
    //   {
    //     path: ":group_id/edit",
    //     name: "GroupEdit",
    //     component: GroupEditView,
    //     props: true,
    //   },
  ],
};

export { MediaMatchRoutes };
