<template>
    <div v-if="friends.length" class="flex space-x-2">
        <label
          class="w-9 h-9 bg-glass-900 rounded-full flex items-center justify-center font-bold text-primary-light border-primary-light"
          :class="{'border-4':me.id==selected}"
        >
          <input type="radio" name="friend" :value="me.id" class="hidden" selected v-model="selected">
          <ion-icon name="person"></ion-icon>
        </label>
        <label
          class="flex w-9 h-9 bg-glass-900 rounded-full items-center justify-center font-bold text-primary-light border-primary-light"
          :class="{'border-4':'G'==selected}"
        >
          <input type="radio" name="friend" value="G" class="hidden" v-model="selected">
          <ion-icon name="people"></ion-icon>
        </label>
        <label
          v-for="friend of friends" :key="friend.id"
          class="w-9 h-9 bg-glass-900 rounded-full flex flex-shrink-0 items-center justify-center font-bold text-primary-light border-primary-light"
          :class="{'border-4':friend.id==selected}"
        >
          <input type="radio" name="friend" :value="friend.id" class="hidden" v-model="selected">
          <span> {{ friend.initials }} </span>
        </label>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data(){
        return {
            selected: undefined
        }
    },
    computed: {
        ...mapGetters('groupState', { friends: 'friendList' }),
        ...mapState('groupState', [ 'active_group' ]),
        ...mapState(['me']),
    },
    watch: {
      selected() {
        this.$emit('change', this.selected)
      }
    },
    beforeMount(){
      this.selected = this.me.id;
    },
}
</script>