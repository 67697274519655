<template>
  <div
    class="flex h-full flex-col items-center justify-around overflow-hidden text-white"
  >
    <img
      v-if="poster"
      class="bg-primary-light aspect-poster min-h-0 flex-shrink rounded-lg"
      :src="poster"
      :alt="suggestion.title"
    />
    <div class="my-4">
      <h1 class="mb-2 text-2xl font-bold md:text-3xl lg:text-4xl">
        {{ suggestion.title }}
      </h1>
      <p
        v-if="suggestion.desc"
        class="bg-glass-900 text-secondary-dark max-h-56 overflow-y-auto rounded-lg px-4 py-2 text-lg"
      >
        {{ suggestion.desc }}
      </p>
    </div>
  </div>
</template>

<script>
import { getResourceUrl } from "../providers/UrlProvider";

export default {
  props: {
    suggestion: Object,
  },
  computed: {
    poster() {
      if (this.suggestion.poster)
        return getResourceUrl(this.suggestion.poster, "w342");
    },
  },
};
</script>
