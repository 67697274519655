function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/** @returns {Promise<PushSubscription>|undefined} */
async function getSubscription() {
  if (!"serviceWorker" in navigator) return undefined;

  const registration = await navigator.serviceWorker.ready;

  // Use the PushManager to get the user's subscription to the push service.
  let subscription = await registration.pushManager.getSubscription();

  return subscription;
}

/** @returns {Promise<PushSubscription>|undefined} */
async function subscribe() {
  if (!"serviceWorker" in navigator) return undefined;

  const registration = await navigator.serviceWorker.ready;

  return registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_VAPID_KEY),
  });
}

export { getSubscription, subscribe };
