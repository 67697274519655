<template>
  <base-overlay-component
    title="Invite a friend"
    @close="$router.replace({ name: 'Groups' })"
  >
    <form class="flex flex-col p-0" @submit.prevent="invite(email)">
      <label class="input mb-2">
        <input ref="email" type="email" placeholder="Email" v-model="email" />
      </label>
      <base-icon-button
        :loading="loading"
        :primary="true"
        tag="button"
        value="Invite"
        icon="chevron-forward"
      ></base-icon-button>
    </form>
  </base-overlay-component>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/providers/HttpProvider";
import BaseIconButton from "../../components/base/BaseIconButton.vue";
import BaseOverlayComponent from "../../components/base/BaseOverlayComponent.vue";

export default {
  props: ["group_id"],
  data() {
    return {
      loading: false,
      email: "",
    };
  },
  methods: {
    ...mapActions("notificationState", ["notify"]),
    async invite(email) {
      try {
        this.loading = true;

        await axios.put(`/groups/${this.group_id}/invite`, { email });

        this.notify({
          title: "Invitation Successful",
          text: `An invitation has been sent to ${email}`,
        });

        this.$router.replace({ name: "Groups" });
      } catch (error) {
        console.error(error);
        this.notify({
          title: "Error",
          text: `Something went wrong`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$refs.email.focus();
  },
  components: { BaseOverlayComponent, BaseIconButton },
};
</script>
