import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
})

const auth = {
    token() {
        const expires = localStorage.getItem('auth.expires');

        if(!expires || Date.now() > expires){
            throw 'Credentials expired!';
        }

        return localStorage.getItem('auth.token');
    },
    async login(email, password) {
        const { data } = await instance.post('/auth/login', { email, password });
        this.store_login(data);
    },
    async register(name, email, password){
        const { data } = await instance.post('/auth/register', { name, email, password });
        this.store_login(data);
    },
    logout() {
        localStorage.removeItem('auth.expires');
        localStorage.removeItem('auth.token');
    },
    store_login(data){
        if(data.token && data.expires_in){
            localStorage.setItem('auth.token', data.token);
            localStorage.setItem('auth.expires', Date.now()+(data.expires_in*1000) );
        }else{
            throw Error("No token contained in login data");
        }
    }
}

export { auth };