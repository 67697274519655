<template>
  <div class="flex h-full w-full place-items-center">
    <form
      class="flex w-full flex-col gap-2"
      @submit.prevent="login($event.target)"
    >
      <h1 class="ml-2 text-2xl font-semibold text-white">Login</h1>
      <label class="input">
        <input type="email" name="email" placeholder="Email" />
      </label>
      <label class="input">
        <input type="password" name="password" placeholder="Password" />
      </label>
      <div class="flex gap-2">
        <base-icon-button
          @click.prevent="$router.push({ name: 'Register' })"
          class="w-1/2"
          value="register"
          icon="create-outline"
        ></base-icon-button>
        <base-icon-button
          :loading="loading"
          tag="button"
          class="w-1/2"
          value="login"
          icon="chevron-forward-outline"
        ></base-icon-button>
      </div>
    </form>
  </div>
</template>

<script>
import BaseIconButton from "@/components/base/BaseIconButton";
import { auth } from "../../providers/AuthProvider";

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async login(form) {
      this.loading = true;

      try {
        const post = new FormData(form);

        await auth.login(post.get("email").toLowerCase(), post.get("password"));

        this.$router.replace({ name: "Main" });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    BaseIconButton,
  },
};
</script>
