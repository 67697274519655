<template>
  <base-overlay-component :title="movie.title" v-on="$listeners">
    <img
      v-if="movie.backdrop"
      :src="getResourceUrl(movie.backdrop, 'w1280')"
      class="mb-2 max-h-32 w-full rounded object-cover object-top"
    />

    <div class="mt-2 flex" v-for="watcher of movie.watchers" :key="watcher.id">
      <figure
        class="text-primary-light border-primary-light flex h-9 w-9 flex-shrink-0 items-center justify-center rounded-full bg-white font-bold shadow"
      >
        {{ initials(watcher.user_id) }}
      </figure>
      <p class="ml-2">
        {{ watcher.comment }}
      </p>
    </div>
  </base-overlay-component>
</template>

<script>
import BaseOverlayComponent from "./base/BaseOverlayComponent.vue";
import { getResourceUrl } from "@/providers/UrlProvider";
import { mapState } from "vuex";

export default {
  components: { BaseOverlayComponent },
  props: {
    media: {
      type: Number,
    },
  },
  methods: {
    getResourceUrl,
    initials(user_id) {
      return (
        this.$store.state.groupState.friends.find((e) => e.id == user_id)
          ?.initials ?? this.me.initials
      );
    },
  },
  computed: {
    movie() {
      return this.$store.getters["watchlistState/media"](this.media);
    },
    ...mapState(["me"]),
  },
};
</script>
