<template>
  <div
    @click="remove(notification.timestamp)"
    class="bg-glass-900 bg-blur text-secondary-dark grid-cols-notification pointer-events-auto grid items-center rounded-lg p-2 shadow-2xl"
  >
    <img
      class="mr-2 h-16 rounded shadow"
      v-if="notification.poster"
      :src="poster"
      alt=""
    />
    <div
      class="h-full flex-grow"
      :class="{ 'col-span-2': !notification.poster }"
    >
      <h1 class="text-lg font-bold">{{ notification.title }}</h1>
      <p>{{ notification.text }}</p>
    </div>
    <div
      class="col-span-2 mt-2 flex justify-between"
      v-if="notification.prompt"
    >
      <button
        class="bg-glass-600 hover:text-secondary-dark rounded px-2 py-1 font-bold hover:text-opacity-75"
        @click="notification.resolve(false)"
      >
        {{ notification.prompt[1] }}
      </button>
      <button
        class="bg-secondary-dark hover:bg-secondary rounded px-2 py-1 font-bold text-white"
        @click="notification.resolve(true)"
      >
        {{ notification.prompt[0] }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { getResourceUrl } from "../../providers/UrlProvider";

export default {
  props: {
    notification: Object,
  },
  computed: {
    poster() {
      return getResourceUrl(this.notification.poster, "w92");
    },
  },
  methods: {
    ...mapMutations("notificationState", ["remove"]),
  },
};
</script>
