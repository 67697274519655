<template>
  <div class="flex h-full w-full flex-col overflow-hidden">
    <div class="mb-2 flex justify-end">
      <router-link :to="{ name: 'Main' }">
        <ion-icon class="text-2xl text-white" name="chevron-forward"></ion-icon>
      </router-link>
    </div>
    <div
      class="mb-2 flex min-h-0 flex-shrink flex-col space-y-2 overflow-x-auto"
    >
      <div
        v-for="group of groups"
        :key="group.id"
        @click="
          $router.push({ name: 'GroupEdit', params: { group_id: group.id } })
        "
        class="bg-glass-800 flex cursor-pointer select-none items-center rounded-xl py-2 px-3 font-semibold text-white"
      >
        <span
          class="bg-glass-200 mr-2 flex items-center rounded-lg py-1 px-2 text-sm font-extralight"
        >
          <ion-icon class="mr-1 text-base" name="people"></ion-icon>
          {{ group.members.length || group.members }}
        </span>
        <span class="py-1 md:text-lg"> {{ group.name }} </span>
        <a
          v-if="group.owner"
          class="hover:bg-glass-200 ml-auto flex cursor-pointer items-center rounded-lg p-2 leading-none md:text-lg"
          @click.stop="
            $router.push({
              name: 'GroupInvite',
              params: { group_id: group.id },
            })
          "
        >
          <ion-icon name="person-add"></ion-icon>
        </a>

        <a
          v-if="group.status == 'PENDING'"
          class="hover:bg-glass-200 ml-auto flex cursor-pointer items-center rounded-lg p-2 leading-none text-red-600 md:text-lg"
          @click.stop="respond(group.id, 'reject')"
        >
          <ion-icon name="close-outline"></ion-icon>
        </a>

        <a
          v-if="group.status == 'PENDING'"
          class="hover:bg-glass-200 ml-2 flex cursor-pointer items-center rounded-lg p-2 leading-none text-green-600 md:text-lg"
          @click.stop="respond(group.id, 'accept')"
        >
          <ion-icon name="checkmark"></ion-icon>
        </a>
      </div>
    </div>

    <base-icon-button
      class="mt-auto"
      @click="$router.replace({ name: 'GroupCreate' })"
      value="new group"
      icon="add"
    ></base-icon-button>

    <transition name="slide-bottom">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import axios from "@/providers/HttpProvider";
import BaseIconButton from "@/components/base/BaseIconButton";

import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      groups(state) {
        return state.groupState.groups;
      },
    }),
  },
  methods: {
    ...mapActions("groupState", ["init"]),
    async respond(group, action) {
      try {
        await axios.put(`/groups/${group}/${action}`);

        if (action == "accept") {
          this.$store.commit("groupState/member", group);
        } else {
          this.$store.commit("groupState/remove", group);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    try {
      await this.init();
    } catch (error) {
      alert(error);
    }
  },
  components: {
    BaseIconButton,
  },
};
</script>
