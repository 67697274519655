import GroupView from "@/views/groups/GroupView";
import GroupInviteView from "@/views/groups/GroupInviteView";
import GroupCreateView from "@/views/groups/GroupCreateView";
import GroupEditView from "@/views/groups/GroupEditView";

const GroupRoutes = {
  path: "/groups",
  name: "Groups",
  component: GroupView,
  children: [
    {
      path: ":group_id/invite",
      name: "GroupInvite",
      component: GroupInviteView,
      props: true,
    },
    {
      path: "add",
      name: "GroupCreate",
      component: GroupCreateView,
    },
    {
      path: ":group_id/edit",
      name: "GroupEdit",
      component: GroupEditView,
      props: true,
    },
  ],
};

export { GroupRoutes };
