<template>
  <div class="relative flex h-full w-full flex-col justify-center">
    <base-menu
      class="z-20"
      :options="options"
      :show="show_menu"
      @close="show_menu = false"
    ></base-menu>
    <a
      :style="{ visibility: !show_menu ? 'visible' : 'hidden' }"
      @click="show_menu = true"
      class="ml-auto cursor-pointer p-2 text-2xl text-white"
    >
      <ion-icon name="menu"></ion-icon>
    </a>
    <div class="mb-12 flex flex-grow flex-col justify-center">
      <movie-table-component
        class="my-auto"
        :spotlight="spotlight"
        :limit="5"
        @select="focus_media = $event"
      ></movie-table-component>
      <group-select-component
        class="mb-2 min-w-0 flex-shrink"
        @change="spotlight = $event"
      ></group-select-component>
    </div>
    <movie-search-component></movie-search-component>
    <transition name="slide-bottom" mode="out-in">
      <watched-detail-component
        v-if="focus_media"
        class="z-10"
        :key="focus_media"
        :media="focus_media"
        @close="focus_media = undefined"
      >
      </watched-detail-component>
    </transition>
  </div>
</template>

<script>
import MovieTableComponent from "@/components/MovieTableComponent";
import MovieSearchComponent from "@/components/MovieSearchComponent";
import GroupSelectComponent from "../components/GroupSelectComponent.vue";
import BaseIconButton from "../components/base/BaseIconButton.vue";
import BaseMenu from "@/components/base/BaseMenu";
import WatchedDetailComponent from "../components/WatchedDetailComponent.vue";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Main",
  data() {
    return {
      spotlight: undefined,
      focus_media: undefined,
      show_menu: false,
      options: [
        {
          text: "Groups",
          icon: "people",
          to: { name: "Groups" },
        },
        {
          text: "All Movies",
          icon: "film",
          to: { name: "AllMovies" },
        },
        {
          text: "Discover",
          icon: "copy",
          to: { name: "Matches" },
        },
        // {
        //   text: "Settings",
        //   icon: "settings",
        //   to: { name: "Settings" },
        // },
      ],
    };
  },
  methods: {
    ...mapActions("notificationState", ["notify"]),
    ...mapActions(["subscribe"]),
    ...mapMutations(["subReject"]),
  },
  components: {
    MovieTableComponent,
    MovieSearchComponent,
    GroupSelectComponent,
    BaseIconButton,
    WatchedDetailComponent,
    BaseMenu,
  },
  async created() {
    if (this.$store.getters.shouldPromptNotifications()) {
      const sub = await this.notify({
        title: "Allow Notifications?",
        text: "Fliks can let you know when your friends watch and rank their favorite movies and shows!",
        tag: "notification-prompt",
        prompt: ["Allow", "No thanks"],
        delay: -1,
      });

      //Sub can be undefined - meaning no choice was made
      if (sub === true) {
        this.subscribe();
      } else if (sub === false) {
        this.subReject();
      }
    }
  },
};
</script>
