<template>
    <div v-if="pick">
        <div class="flex justify-center place-items-center overflow-hidden flex-grow flex-shrink min-h-0">
            <img class="h-full bg-secondary-dark text-transparent rounded-2xl" style="aspect-ratio: 60/89;" :src="getResourceUrl(pick.poster, 'w342')" :alt="pick.title">
        </div>
        <label class="mb-2">
            <span class="text-white font-bold capitalize ml-2" >comment</span><br>
            <textarea class="bg-white h-20 rounded-2xl w-full resize-none focus-within:ring-2 focus-within:ring-secondary-light border-0" placeholder="🍿🎬" v-model="comment"></textarea>
        </label>
        <base-icon-button
            class="place-self-end"
            value="continue"
            icon="chevron-forward-outline"
            @click="$emit('movie-init', {'comment': comment})"
        ></base-icon-button>
    </div>
</template>

<script>
import BaseIconButton from '@/components/base/BaseIconButton';
import { getResourceUrl } from "@/providers/UrlProvider";

export default {
    props: ['pick'],
    data(){
        return {
            "comment": ""
        }
    },
    methods: {
        getResourceUrl
    },
    components: {
        BaseIconButton
    }
}
</script>

<style scoped>

    img {
        box-shadow: var(--shadow);
    }

    .comment span {
        color: white;
        font-size: 0.75;
        text-transform: uppercase;
        font-weight: bold;
        padding-left: 0.5rem;
    }

    .comment textarea {
        background: white;
        padding: 1.2rem;
        font-size: 1.2rem;
        box-sizing: border-box;
        box-shadow: var(--shadow);
        border-radius: 1.5rem;
        border: none;
        outline: none;
        width: 100%;
        resize: none;

    }

    textarea::placeholder {
        transition: opacity 200ms ease-out;
        opacity: 0.8;
    }

    textarea:focus::placeholder {
        opacity: 0;
    }

</style>

