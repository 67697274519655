<template>
    <table class="w-full glass rounded-lg flex-shrink min-h-0">
        <thead class="bg-glass-600">
            <tr>
                <th class="text-center">#</th>
                <th class="text-left">movie</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="movie of watchlist" :key="movie.id" class="bg-glass-400 hover:bg-glass-600 cursor-pointer"
                @click="$emit('select', movie.id)"
            >
                <td class="text-center overflow-">{{rank(movie.rank)}}</td>
                <td class="text-left overflow-ellipsis">{{movie.title}} </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapActions } from 'vuex';
import { getResourceUrl } from "@/providers/UrlProvider";

export default {
    props: {
        spotlight: {
            default: "M"
        },
        limit: {
            type: Number
        },
        search: {
            type: String
        }
    },
    methods: {
        rank(rank) {
            return {
                1: "🥇",
                2: "🥈",
                3: "🥉"
            }[rank] ?? rank;
        },
        ...getResourceUrl,
        ...mapActions('watchlistState', {
            getWatchList: 'init'
        })
    },
    computed: {
        watchlist() {
            let list = this.$store.getters['watchlistState/watchlist'](this.spotlight).sort((a, b) => { return a.rank - b.rank });

            if(this.search) list = list.filter(el => {
                return el.title.toLowerCase().includes(this.search.toLowerCase())
                   ||  el.genres.some(g => g.toLowerCase() == this.search.toLowerCase())
            });

            if(this.limit) list = list.slice(0, this.limit);

            return list;
        }
    },
    beforeMount(){
        try {
            this.getWatchList();
        } catch (error) {
            alert(error);
        }
    }
}
</script>

<style scoped>

    .glass {
        transform: translate(0, 0);
        border-spacing: 0;
        table-layout: fixed;
    }

    .glass thead th:nth-child(1) { width: 4ch; }
    .glass thead th:nth-child(2) { width: 100%; }
    .glass thead th:nth-child(3) { width: 24ch; }

    .glass thead th:first-child {
        border-top-left-radius: 0.5rem;
    }

    .glass thead th:last-child {
        border-top-right-radius: 0.5rem;
    }

    .glass tbody tr:last-child td:first-child{
        border-bottom-left-radius: 0.5rem;
    }

    .glass tbody tr:last-child td:last-child{
        border-bottom-right-radius: 0.5rem;
    }

    .glass td, .glass th {
        white-space: nowrap;
        overflow: hidden;
        padding: 0.5rem;
        color: white;
    }

    .glass th {
        text-transform: uppercase;
    }

    .comment::after {
        content: '';
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        top: 100%;
        left: 90%;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-top: 0.5rem solid white;
    }
    
    .glass tr .comment {
        position: absolute;
        left: 0;
        bottom: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        text-align: center;

        transform: translateX(-2rem);
        opacity: 0.0;
        transition: all 250ms ease-in-out;
    }

    .glass tr:hover .comment {
        transform: translateX(0);
        opacity: 1.0;
    }
	
    @-moz-document url-prefix() {
        table {
            overflow: hidden;
        }
    }

</style>