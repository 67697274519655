import axios from "@/providers/HttpProvider";

function getUserWatchlist(state, spotlight) {
  return state.watchlist
    .filter((watch) => {
      return watch.watchers.some((user) => user.user_id == spotlight);
    })
    .map((watch) => {
      const rank = watch.watchers.find(
        (user) => user.user_id == spotlight
      ).rank;
      return {
        ...watch,
        rank,
      };
    });
}

function getGroupWatchlist(state) {
  return state.watchlist.map((watch) => {
    const rank = Math.floor(
      watch.watchers.reduce((acc, cur) => acc + cur.rank, 0) /
        watch.watchers.length
    );
    return {
      ...watch,
      rank,
    };
  });
}

const watchlistState = {
  namespaced: true,
  state: {
    watchlist: [],
  },
  getters: {
    watchlist: (state) => (spotlight) => {
      /* spotlight is the user id to filter for */
      const watchlist =
        spotlight == "G"
          ? getGroupWatchlist(state)
          : getUserWatchlist(state, spotlight);

      return watchlist;
    },
    media: (state) => (media_id) => {
      let media = { ...state.watchlist.find((e) => e.id == media_id) };

      media.watchers = media.watchers.filter(
        (watcher) => watcher.comment.length
      );

      return media;
    },
  },
  mutations: {
    init: (state, watchlist) => {
      state.watchlist = watchlist;
    },
  },
  actions: {
    init: async ({ rootState, commit }) => {
      //TODO don't reload every time

      const url = rootState.groupState.active_group
        ? `/groups/${rootState.groupState.active_group}/watchlist`
        : `/media/watchlist`;

      try {
        let { data: watchlist } = await axios.get(url);
        commit("init", watchlist);
      } catch (error) {
        throw error;
      }
    },
  },
};

export default watchlistState;
