<template>
  <div
    class="bg-blur bg-glass-900 text-secondary grid-rows-overlay fixed inset-x-0 bottom-0 mx-auto grid max-h-96 max-w-2xl overflow-hidden rounded-t-2xl shadow-xl md:bottom-1/2 md:rounded-2xl"
  >
    <div class="flex items-center px-4 pt-2">
      <span class="font-bold uppercase">{{ title }}</span>
      <a class="ml-auto flex cursor-pointer text-xl" @click="$emit('close')">
        <ion-icon name="close"></ion-icon>
      </a>
    </div>
    <div class="overflow-y-auto p-4 pt-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
//.slide-bottom-enter-active
//.slide-bottom-leave-active
//.slide-bottom-enter-to
//.slide-bottom-leave
//.slide-bottom-enter
//.slide-bottom-leave-to

export default {
  props: ["title"],
};
</script>
