<template>
  <div class="pointer-events-none h-full w-full">
    <transition-group name="screen" class="space-y-2">
      <notification-component
        v-for="notification of notifications"
        :key="notification.timestamp"
        :notification="notification"
      ></notification-component>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationComponent from "./NotificationComponent.vue";

export default {
  computed: {
    ...mapState("notificationState", ["notifications"]),
  },
  components: { NotificationComponent },
};
</script>

<style scoped>
.screen-leave-active {
  position: relative;
}

.screen-leave-active,
.screen-enter-active {
  transition: transform 200ms ease-in-out, opacity 100ms ease-in-out;
}

.screen-enter-to,
.screen-leave {
  transform: scale(1);
  opacity: 1;
}

.screen-enter {
  transform: scale(0.9);
  opacity: 0;
}

.screen-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
</style>
