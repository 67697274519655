import axios from "@/providers/HttpProvider";

const matchState = {
  namespaced: true,
  state: {
    suggestions: [],
  },
  getters: {
    getSuggestion(state) {
      return state.suggestions[0];
    },
  },
  mutations: {
    setSuggestions(state, suggestions) {
      state.suggestions = state.suggestions.concat(suggestions);
    },
    nextSuggestion(state) {
      state.suggestions = state.suggestions.slice(1);
    },
  },
  actions: {
    async getSuggestions({ state, commit }) {
      if (state.suggestions.length > 1) return;

      try {
        const { data } = await axios.get("/matches/suggestions");
        commit("setSuggestions", data["suggestions"]);
      } catch (error) {
        throw error;
      }
    },
    async makeMatch({ state, getters, commit, dispatch, rootState }, matched) {
      try {
        const media = { ...getters.getSuggestion };
        const { data } = await axios.post("/matches", {
          media_id: media.ext_id,
          media_type: "movie",
          matched: matched,
          active_group: rootState.groupState.active_group,
        });

        if (state.suggestions.length <= 1) await dispatch("getSuggestions");

        commit("nextSuggestion");

        return {
          matches: data.matches,
          media,
        };
      } catch (error) {
        throw error;
      }
    },
  },
};

export default matchState;
