<template>
  <base-overlay-component
    v-if="group"
    :title="'manage ' + group.name"
    @close="$router.replace({ name: 'Groups' })"
  >
    <div class="flex flex-col gap-2">
      <div
        v-for="member of group.members"
        :key="member.id"
        class="flex items-center rounded-xl bg-white p-2 shadow-lg"
      >
        <ion-icon class="mr-2" name="person"></ion-icon>
        <span class="font-medium">{{ member.name }}</span>
        <span class="ml-2 hidden font-medium opacity-50 md:inline"
          >({{ member.email }})</span
        >

        <a
          v-if="group.owner"
          class="hover:bg-secondary ml-auto flex cursor-pointer items-center rounded-lg p-1 leading-none hover:text-white md:text-lg"
          @click="exclude(member.email)"
        >
          <ion-icon name="close"></ion-icon>
        </a>
      </div>
      <div class="flex gap-2">
        <base-icon-button
          v-if="group.owner"
          value="vanquish"
          icon="trash"
          class="flex-grow"
          :primary="true"
          :loading="dLoading"
          @click="vanquish"
        ></base-icon-button>
        <base-icon-button
          class="ml-auto"
          :icon_left="true"
          :icon="active_group == group.id ? 'heart' : 'heart-outline'"
          @click="setActiveGroup(group.id)"
        ></base-icon-button>
      </div>
    </div>
  </base-overlay-component>
</template>

<script>
import BaseIconButton from "../../components/base/BaseIconButton.vue";
import BaseOverlayComponent from "../../components/base/BaseOverlayComponent.vue";

import { mapState, mapActions } from "vuex";

export default {
  props: ["group_id"],
  data() {
    return {
      loading: false,
      dLoading: false,
    };
  },
  methods: {
    ...mapActions("groupState", [
      "remove",
      "fetchMembers",
      "removeMember",
      "setActiveGroup",
    ]),
    ...mapActions("notificationState", ["notify"]),
    async vanquish() {
      const del = await this.notify({
        title: "Confirm",
        text: `Are you sure you wish to vanquish ${this.group.name}?`,
        prompt: ["Vanquish", "Cancel"],
      });

      if (del) {
        try {
          this.dLoading = true;
          this.$router.replace({ name: "Groups" });
          await this.remove(this.group.id);
        } catch (error) {
          this.notify({
            title: "Error",
            text: `Could not delete ${this.group.name}`,
          });
        } finally {
          this.dLoading = false;
        }
      }
    },
    async exclude(email) {
      try {
        await this.removeMember({ group_id: this.group.id, email });
      } catch (error) {
        this.notify({
          title: "Error",
          text: `Could not remove ${email} user from the group.`,
        });
      }
    },
    async init() {
      this.loading = true;
      try {
        await this.fetchMembers(this.group_id);
      } catch (error) {
        this.notify({
          title: "Error",
          text: "Could not load group content",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState("groupState", ["active_group"]),
    group() {
      return (
        this.$store.getters["groupState/getGroupById"](this.group_id) ?? {}
      );
    },
  },
  components: {
    BaseIconButton,
    BaseOverlayComponent,
  },
  async beforeUpdate() {
    await this.init();
  },
  async beforeMount() {
    await this.init();
  },
};
</script>
