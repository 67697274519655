<template>
  <div class="grid-rows-app grid h-full w-full">
    <div class="flex">
      <router-link class="ml-auto" :to="{ name: 'Main' }">
        <ion-icon class="text-2xl text-white" name="chevron-forward"></ion-icon>
      </router-link>
    </div>
    <div class="relative">
      <transition :name="transition">
        <media-match-suggestion-component
          :key="suggestion.ext_id"
          :suggestion="suggestion"
        ></media-match-suggestion-component>
      </transition>
    </div>
    <div class="flex justify-between">
      <base-icon-button value="reject" icon="close" @click="match(false)">
      </base-icon-button>
      <base-icon-button
        icon="star"
        @click="
          $router.push({
            name: 'MovieAdd',
            params: { media_type: 'movie', media_id: suggestion.ext_id },
          })
        "
      >
      </base-icon-button>
      <base-icon-button
        value="watch"
        icon="heart"
        :icon_left="true"
        :primary="true"
        @click="match(true)"
      >
      </base-icon-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseIconButton from "../../components/base/BaseIconButton.vue";
import MediaMatchSuggestionComponent from "../../components/MediaMatchSuggestionComponent.vue";

export default {
  data() {
    return {
      prevMatched: undefined,
    };
  },
  computed: {
    ...mapGetters("matchState", {
      suggestion: "getSuggestion",
    }),
    transition() {
      const match = this.prevMatched ? "yes" : "no";
      return `slide-${match}`;
    },
  },
  methods: {
    ...mapActions("matchState", ["makeMatch"]),
    ...mapActions("notificationState", ["notify"]),
    async match(matched) {
      this.prevMatched = matched;
      const { matches, media } = await this.makeMatch(matched);

      if (Object.values(matches).length) {
        for (let match of Object.values(matches)) {
          this.notify({
            title: `Matched with ${match.name} - ${media.title}`,
            text: `You matched with ${match.name} - They also want to watch ${media.title}`,
            poster: media.poster,
          });
        }
      }
    },
  },
  components: {
    BaseIconButton,
    MediaMatchSuggestionComponent,
  },
};
</script>

<style scoped>
.slide-yes-leave-active,
.slide-no-leave-active {
  position: absolute;
}

.slide-yes-leave-active,
.slide-no-leave-active,
.slide-yes-enter-active,
.slide-no-enter-active {
  transform-origin: bottom center;
  transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
}

.slide-yes-enter-to,
.slide-no-enter-to,
.slide-no-leave,
.slide-yes-leave {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

.slide-yes-enter,
.slide-no-enter {
  transform: scale(0.9);
  opacity: 0;
}

.slide-yes-leave-to {
  transform: rotate(10deg);
  opacity: 0;
}

.slide-no-leave-to {
  transform: rotate(-10deg);
  opacity: 0;
}
</style>
