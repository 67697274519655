import MovieAddBase from "../views/MovieAddBase.vue";
import MovieAddInit from "../views/MovieAddInit.vue";
import MovieAddRate from "../views/MovieAddRate.vue";
import MovieAddFinalize from "../views/MovieAddFinalize.vue";

const MovieAddRoutes = {
  path: "/movies/add/:media_type(tv|movie)/:media_id(\\d+)",
  props: true,
  component: MovieAddBase,
  children: [
    {
      path: "init",
      name: "MovieAdd",
      component: MovieAddInit,
    },
    {
      path: "rate",
      name: "MovieAddRate",
      component: MovieAddRate,
    },
    {
      path: "finalize",
      name: "MovieAddFinalize",
      component: MovieAddFinalize,
    },
  ],
};

export { MovieAddRoutes };
