const notificationState = {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    notify(state, notification) {
      if (
        notification.tag &&
        state.notifications.find((n) => n.tag == notification.tag)
      ) {
        return;
      }

      state.notifications.push(notification);
    },
    remove(state, timestamp) {
      const index = state.notifications.findIndex(
        (n) => n.timestamp == timestamp
      );

      if (index < 0) return;

      if (state.notifications[index].prompt) {
        state.notifications[index].resolve(undefined);
      }

      state.notifications.splice(index, 1);
    },
  },
  actions: {
    notify({ commit }, notification) {
      return new Promise((resolve, reject) => {
        const now = Date.now();

        notification.timestamp = now;

        if (notification.prompt) {
          notification.resolve = resolve;
          notification.reject = reject;
        } else {
          resolve();
        }

        commit("notify", notification);

        if (notification.delay === undefined || notification.delay >= 0) {
          setTimeout(() => {
            commit("remove", now);
          }, notification.delay || 6000);
        }
      });
    },
  },
};

export default notificationState;
