<template>
  <transition name="shrink">
    <div
      v-if="options.length && show"
      class="fill-w fill-h absolute top-0 right-0 z-20"
    >
      <ul
        class="bg-glass-800 bg-blur text-secondary capitaliz rounded-lg py-2 text-xl capitalize shadow-xl"
      >
        <li class="flex items-center px-2 transition-colors">
          <a
            class="hover:bg-glass-900 ml-auto block rounded-full p-1"
            @click="$emit('close')"
          >
            <ion-icon name="close" class="block"></ion-icon>
          </a>
        </li>
        <li v-for="option of options">
          <router-link
            :to="option.to"
            class="hover:bg-glass-900 flex items-center px-3 py-2 transition-colors"
          >
            <ion-icon :name="option.icon" class="mr-2"></ion-icon>
            {{ option.text }}
          </router-link>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BaseMenu",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    show: Boolean,
  },
};
</script>

<style>
.shrink-leave-active,
.shrink-enter-active {
  transition: transform 200ms ease-in-out, opacity 100ms ease-in-out;
  transform-origin: top right;
}

.shrink-enter-to,
.shrink-leave {
  transform: scale(1);
  opacity: 1;
}

.shrink-leave-to,
.shrink-enter {
  transform: scale(0.3);
  opacity: 0;
}
</style>
