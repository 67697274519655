import axios from "@/providers/HttpProvider";

function cmp_groups(a, b) {
  if (a.owner > b.owner) {
    return -1;
  } else if (a.owner == b.owner) {
    if (a.status == "MEMBER" && b.status != "MEMBER") {
      return -1;
    } else if (a.status == b.status) {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }
  }

  return 1;
}

const groupState = {
  namespaced: true,
  state: {
    groups: [],
    friends: [],
    active_group: undefined,
  },
  getters: {
    getGroupById: (state) => (id) => {
      return state.groups.find((e) => e.id == id);
    },
    friendList: (state, _, rootState) => {
      return state.friends.filter((f) => f.id != rootState.me.id);
    },
  },
  mutations: {
    init: (state, groups) => {
      state.groups = groups.sort((a, b) => cmp_groups(a, b));
    },
    set_active_group: (state, { friends, group }) => {
      localStorage.setItem("groups.active", group);
      state.friends = friends;
      state.active_group = group;
    },
    add: (state, group) => {
      const index = state.groups.findIndex((a) => cmp_groups(a, group) == 1);
      state.groups.splice(index, 0, group);
    },
    remove: (state, id) => {
      const index = state.groups.findIndex((el) => el.id == id);
      state.groups.splice(index, 1);
    },
    update: (state, { id, group }) => {
      const index = state.groups.findIndex((el) => el.id == id);
      if (index == -1) return;
      Object.assign(state.groups[index], group);
    },
    member: (state, id) => {
      id = state.groups.findIndex((el) => el.id == id);

      if (!state.groups[id]) return;
      state.groups[id].members += 1;
      state.groups[id].status = "MEMBER";
    },
  },
  actions: {
    init: async ({ commit }) => {
      //TODO: Implement version system / websocket events so groups are updated live.
      //if(state.groups.length) return;

      try {
        let res = await axios.get(`/auth/groups`);
        commit("init", res.data);
      } catch (error) {
        throw error;
      }
    },
    setActiveGroup: async ({ commit }, group) => {
      if (!group) return;

      try {
        let { data: friends } = await axios.get(`/groups/${group}`);
        commit("set_active_group", { friends, group });
      } catch (error) {
        throw error;
      }
    },
    remove: async ({ commit }, id) => {
      try {
        await axios.delete(`/groups/${id}`);
        commit("remove", id);
      } catch (error) {
        throw error;
      }
    },
    fetchMembers: async ({ getters, commit }, id) => {
      if (getters.getGroupById(id)?.members instanceof Object) return;

      try {
        const { data } = await axios.get(`/groups/${id}`);
        commit("update", { id, group: { members: data } });
      } catch (error) {
        throw error;
      }
    },
    removeMember: async ({ getters, commit }, { group_id, email }) => {
      try {
        await axios.put(`/groups/${group_id}/exclude`, { email });
        const group = getters.getGroupById(group_id);
        commit("update", {
          id: group_id,
          group: {
            members: group.members.filter((e) => e.email != email),
          },
        });
      } catch (error) {
        throw error;
      }
    },
  },
};

export default groupState;
