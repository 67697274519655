<template>
    <div v-if="pick">
        <h2 class="text-white text-center" >How does it compare to <br> <b> {{ pick.title }}</b>? </h2>
        <div class="flex justify-center place-items-center overflow-hidden flex-grow flex-shrink min-h-0">
            <img class="h-full bg-secondary-dark text-transparent rounded-2xl" style="aspect-ratio: 60/89;" :src="getResourceUrl(pick.poster, 'w342')" :alt="pick.title">
        </div>
        <div class="flex m-4 justify-around">
            <base-icon-button
                value="worse"
                icon="sad-outline"
                :icon_left="true"
                @click="rate(false)"
            ></base-icon-button>
            <base-icon-button
                value="better"
                icon="happy-outline"
                @click="rate(true)"
            ></base-icon-button>
        </div>
    </div>
</template>

<script>
import BaseIconButton from "../components/base/BaseIconButton.vue";
import { getResourceUrl } from "@/providers/UrlProvider";

export default {
    props: ['watchlist'],
    methods: {
        getResourceUrl,
        rate(rating){
            this.$emit('movie-rate', { rating });
        }
    },
    computed: {
        pick(){
            const M = Math.floor((this.watchlist.length-1)/2);
            return this.watchlist[M];
        }
    },
    components: { BaseIconButton }
}
</script>