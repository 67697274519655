<template>
  <base-overlay-component
    title="Create new group"
    @close="$router.replace({ name: 'Groups' })"
  >
    <form class="flex flex-col p-0" @submit.prevent="create(group)">
      <label class="input mb-2 min-w-0 flex-shrink shadow-xl">
        <input ref="group" type="text" placeholder="Group" v-model="group" />
      </label>
      <base-icon-button
        class="shadow-xl"
        :loading="loading"
        :primary="true"
        tag="button"
        value="Create"
        icon="add"
      ></base-icon-button>
    </form>
  </base-overlay-component>
</template>

<script>
import axios from "@/providers/HttpProvider";
import BaseIconButton from "../../components/base/BaseIconButton.vue";
import BaseOverlayComponent from "../../components/base/BaseOverlayComponent.vue";

export default {
  props: ["group_id"],
  data() {
    return {
      loading: false,
      group: "",
    };
  },
  methods: {
    async create(name) {
      try {
        this.loading = true;

        let { data } = await axios.post("/groups", { name });

        this.$store.commit("groupState/add", data.resource);

        this.$router.replace({ name: "Groups" });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$refs.group.focus();
  },
  components: { BaseOverlayComponent, BaseIconButton },
};
</script>
