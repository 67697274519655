<template>
    <div class="w-full h-full flex place-items-center">
        <form class="w-full flex flex-col gap-2" @submit.prevent="register($event.target)" >
            <h1 class="text-white text-2xl ml-2 font-semibold">Register</h1>
            <label class="input">
                <input type="text" name="name" placeholder="John Doe">
            </label>
            <label class="input">
                <input type="text" name="email" placeholder="Email">
            </label>
            <label class="input">
                <input type="password" name="password" placeholder="Password">
            </label>
            <base-icon-button
                :loading="loading"
                tag="button"
                value="Register"
                icon="chevron-forward-outline"
            ></base-icon-button>
        </form>
    </div>
</template>

<script>
import BaseIconButton from '@/components/base/BaseIconButton';
import { auth } from '../../providers/AuthProvider';

export default {
    data(){
        return {
            loading: false,
        }
    },
    methods: {
        async register(form){
            try {
                this.loading = true;

                const post = new FormData(form);

                await auth.register(
                    post.get('name'),
                    post.get('email').toLowerCase(),
                    post.get('password'),
                );

                this.$router.replace({name:"Main"})
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        }
    },
    components: {
        BaseIconButton
    }
}
</script>