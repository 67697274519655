<template>
  <div
    id="app"
    class="from-primary-dark to-secondary-dark fixed h-full w-full overflow-hidden bg-gradient-to-br p-4"
  >
    <div
      name="route-container"
      class="relative mx-auto h-full w-full sm:w-2/3 lg:w-1/2 2xl:w-1/3"
    >
      <transition name="screen">
        <router-view />
      </transition>
      <notification-group-component
        class="absolute inset-0 z-50 space-y-1"
      ></notification-group-component>
    </div>
  </div>
</template>

<script>
import NotificationGroupComponent from "./components/notifications/NotificationGroupComponent.vue";

export default {
  components: {
    NotificationGroupComponent,
  },
};
</script>

<style scoped>
.screen-leave-active {
  position: absolute;
  top: 0;
  left: 0;
}

.screen-leave-active,
.screen-enter-active {
  transition: transform 200ms ease-in-out, opacity 100ms ease-in-out;
}

.screen-enter-to,
.screen-leave {
  transform: scale(1);
  opacity: 1;
}

.screen-enter {
  transform: scale(0.9);
  opacity: 0;
}

.screen-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
</style>
